// @ts-strict-ignore
import _ from 'lodash';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class ManualConditionStore extends BaseToolStore {
  static readonly storeName = 'sqManualConditionStore';
  type = TREND_TOOLS.MANUAL_CONDITION;

  initialize() {
    this.state = this.immutable({ ...BASE_TOOL_COMMON_PROPS });
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the references panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Removes properties from config which are stored as part of the formula.
   *
   * @param {Object} config - The state that will be saved to UIConfig
   * @return {Object} The modified config
   */
  modifyConfigParams(config) {
    return _.omit(config, ['maximumDuration', 'advancedParametersCollapsed']);
  }

  handlers = _.assign({}, super.baseHandlers);
}

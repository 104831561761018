// @ts-strict-ignore
import _ from 'lodash';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { DIGITAL_FILTER_CUTOFF } from '@/tools/digitalFilter/digitalFilter.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';

export class DigitalFilterStore extends BaseToolStore {
  static readonly storeName = 'sqDigitalFilterStore';
  type = TREND_TOOLS.DIGITAL_FILTER;
  parameterDefinitions = {
    inputSignal: { predicate: ['name', 'inputSignal'] },
  };

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, this.parameterDefinitions, {
        filterType: undefined,
        cutoff: DIGITAL_FILTER_CUTOFF,
        cutoff2: DIGITAL_FILTER_CUTOFF,
        samplingRate: DIGITAL_FILTER_CUTOFF,
        windowSize: DIGITAL_FILTER_CUTOFF,
        isAutoSamplingRate: true,
        isAutoWindowSize: true,
      }),
    );
  }

  get inputSignal() {
    return this.state.get('inputSignal');
  }

  get filterType(): string {
    return this.state.get('filterType');
  }

  get cutoff(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('cutoff');
  }

  get cutoff2(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('cutoff2');
  }

  get samplingRate(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('samplingRate');
  }

  get windowSize(): ValueWithUnitsItem & { valid?: boolean } {
    return this.state.get('windowSize');
  }

  get isAutoSamplingRate() {
    return this.state.get('isAutoSamplingRate');
  }

  get isAutoWindowSize() {
    return this.state.get('isAutoWindowSize');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    ...this.baseHandlers,

    DIGITAL_FILTER_SET_FILTER_TYPE: (payload: { filterType: string }) => {
      this.state.set('filterType', payload.filterType);
    },

    DIGITAL_FILTER_SET_CUTOFF: (payload: { cutoff: string }) => {
      this.state.set('cutoff', payload.cutoff);
    },

    DIGITAL_FILTER_SET_CUTOFF_2: (payload: { cutoff2: string }) => {
      this.state.set('cutoff2', payload.cutoff2);
    },

    DIGITAL_FILTER_SET_SAMPLING_RATE: (payload: { samplingRate: string }) => {
      this.state.set('samplingRate', payload.samplingRate);
    },

    DIGITAL_FILTER_SET_WINDOW_SIZE: (payload: { windowSize: string }) => {
      this.state.set('windowSize', payload.windowSize);
    },

    DIGITAL_FILTER_SET_IS_AUTO_SAMPLING_RATE: (payload: { isAutoSamplingRate: boolean }) => {
      this.state.set('isAutoSamplingRate', payload.isAutoSamplingRate);
    },

    DIGITAL_FILTER_SET_IS_AUTO_WINDOW_SIZE: (payload: { isAutoWindowSize: boolean }) => {
      this.state.set('isAutoWindowSize', payload.isAutoWindowSize);
    },
  };
}

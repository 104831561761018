import { isTelemetryEnabled } from '@/services/systemConfiguration.utilities';

export abstract class TrackerService {
  protected constructor() {}

  abstract trackEvent(category: string, action: string, information: string, uniqueEventId: number): void;

  shouldTrack() {
    return isTelemetryEnabled();
  }
}

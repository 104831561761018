// @ts-strict-ignore
import _ from 'lodash';
import { convertDuration, splitDuration } from '@/datetime/dateTime.utilities';
import { StoredStatistic } from '../StatisticSelector.molecule';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { InterpolationMethod, SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { getDefaultMaxCapsuleDuration } from '@/services/systemConfiguration.utilities';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export class SignalFromConditionStore extends BaseToolStore {
  static readonly storeName = 'sqSignalFromConditionStore';
  parameterDefinitions = {
    inputItem: { predicate: ['name', 'series'] },
    condition: { predicate: ['name', 'capsules'] },
  };
  type = TREND_TOOLS.SIGNAL_FROM_CONDITION;

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, this.parameterDefinitions, {
        interpolation: InterpolationMethod.DISCRETE,
        stat: {
          key: undefined,
          timeUnits: 's',
        },
        maximumDurationInputOverride: undefined,
        maximumDurationBoundingOverride: undefined,
        keyMethod: undefined,
      }),
    );
  }

  get inputItem() {
    return this.state.get('inputItem');
  }

  get condition() {
    return this.state.get('condition');
  }

  get interpolation(): InterpolationMethod {
    return this.state.get('interpolation');
  }

  get stat(): StoredStatistic {
    return this.state.get('stat');
  }

  get maximumDurationInputOverride(): ValueWithUnitsItem {
    return this.state.get('maximumDurationInputOverride') || getDefaultMaxCapsuleDuration();
  }

  get maximumDurationBoundingOverride(): ValueWithUnitsItem {
    return this.state.get('maximumDurationBoundingOverride') || getDefaultMaxCapsuleDuration();
  }

  get keyMethod(): string {
    return this.state.get('keyMethod');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  getStatAndParameters(formula: string) {
    const match = /\.aggregate\((\w*)\((?:'|")?(.*?)(?:'|")?\)/.exec(formula);
    if (!match) {
      return { stat: null, parameters: { timeUnits: 's' } };
    }

    const [full, operator, arg] = match;
    const stat = _.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, (valueMethod) => _.startsWith(valueMethod.stat, operator));
    if (stat.key === 'percentile') {
      const percentile = parseInt(arg, 10);
      return {
        stat,
        parameters: {
          timeUnits: 's',
          percentile: _.isNaN(percentile) ? null : percentile,
        },
      };
    } else {
      const timeUnits =
        _.chain([arg])
          .reject((text) => _.toLower(text) === 'true' || _.toLower(text) === 'false')
          .head()
          .value() || 's';
      return { stat, parameters: { timeUnits, percentile: null } };
    }
  }

  /**
   * Removes properties from config which are stored as part of the formula.
   * Signal from condition gets everything it needs from the formula so the config only contains the tool type and
   * whether the advanced section is expanded or not.
   *
   * @param {Object} config - The state that will be saved to UIConfig
   * @return {Object} The modified config
   */
  modifyConfigParams(config) {
    return _.pick(config, ['type', 'advancedParametersCollapsed']);
  }

  protected readonly handlers = {
    ...this.baseHandlers,

    SIGNAL_FROM_CONDITION_SET_INTERPOLATION: (payload: { interpolation: keyof typeof InterpolationMethod }) => {
      this.state.set('interpolation', payload.interpolation);
    },

    SIGNAL_FROM_CONDITION_SET_STAT: (payload: { stat: StoredStatistic }) => {
      this.state.set('stat', payload.stat);
    },

    SIGNAL_FROM_CONDITION_SET_INPUT_OVERRIDE: (payload: { inputOverride: ValueWithUnitsItem }) => {
      this.state.set('maximumDurationInputOverride', payload.inputOverride);
    },

    SIGNAL_FROM_CONDITION_SET_BOUNDING_OVERRIDE: (payload: { boundingOverride: ValueWithUnitsItem }) => {
      this.state.set('maximumDurationBoundingOverride', payload.boundingOverride);
    },

    SIGNAL_FROM_CONDITION_SET_KEY_METHOD: (payload: { keyMethod: string }) => {
      this.state.set('keyMethod', payload.keyMethod);
    },

    /**
     * Adds the formula and parameters to the config as part of what gets rehydrated when the tool is loaded.
     *
     * @param {Object} payload - An object with the necessary state to populate the edit form.
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     * @param {Object[]} payload.parameters - The parameters used in the formula
     * @param {String} payload.selectedOperator - The selected operator from the UI Config
     */
    TOOL_REHYDRATE_FOR_EDIT: (payload: { type: string; formula?: string }) => {
      if (payload?.type === TREND_TOOLS.SIGNAL_FROM_CONDITION) {
        this.rehydrateForEdit(payload);

        const inputOverrideDuration = _.get(/\$series\.setMaximumDuration\((.*?)\)/.exec(payload.formula), '1');
        const boundingOverrideDuration = _.get(/\$capsules\.setMaximumDuration\((.*?)\)/.exec(payload.formula), '1');
        const { stat, parameters } = this.getStatAndParameters(payload.formula);
        const keyMethod = _.get(/(\w*?)Key\(\)/.exec(payload.formula), '1');

        let interpolation;
        const isStep = (payload.formula || '').indexOf('toStep()') > -1;
        if (isStep) {
          interpolation = InterpolationMethod.STEP;
        } else if (_.get(payload, 'signalMetadata.maxInterpolation.value', 0) > 0) {
          interpolation = InterpolationMethod.LINEAR;
        } else {
          interpolation = InterpolationMethod.DISCRETE;
        }

        const maxInterpolation = convertDuration(_.get(payload, 'signalMetadata.maxInterpolation'));
        this.state.set('signalMetadata', { maxInterpolation });

        this.state.set('interpolation', interpolation);
        this.state.set('stat', {
          key: _.get(stat, 'key'),
          timeUnits: _.get(parameters, 'timeUnits'),
          percentile: _.get(parameters, 'percentile'),
        });
        this.state.set('keyMethod', keyMethod);
        this.state.set(
          'maximumDurationInputOverride',
          splitDuration(inputOverrideDuration) || getDefaultMaxCapsuleDuration(),
        );
        this.state.set(
          'maximumDurationBoundingOverride',
          splitDuration(boundingOverrideDuration) || getDefaultMaxCapsuleDuration(),
        );
      }
    },
  };
}

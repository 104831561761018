import _ from 'lodash';
import { errorToast, infoToast } from '@/utilities/toast.utilities';
import { flux } from '@/core/flux.module';
import { sqInvestigateStore, sqTrendCapsuleStore, sqTrendStore } from '@/core/core.stores';
import { CapsuleGroupCapsule } from '@/tools/manualCondition/manualCondition.constants';
import { requestCapsulesFromFormula } from '@/tools/manualCondition/conditionFormula.service';
import { removeTrendSelectedRegion, replaceCapsuleSelection } from '@/trendData/trend.actions';

/**
 * Sets the capsules in the capsule group store
 *
 * @param {Object} capsules - array of capsule objects
 */
export function setCapsules(capsules: CapsuleGroupCapsule[]) {
  flux.dispatch('CAPSULE_GROUP_SET_CAPSULES', { capsules });
}

/**
 * Removes all the capsules from the capsule group store
 */
export function clearCapsules() {
  setCapsules([]);
}

/**
 * Requests the capsules for the `capsuleSeries` formula and restores any of those capsules that are from selection
 * and sets the capsules back into the store. A notification is shown to inform the users that the selection was
 * cleared with the option to merge it with the existing selection.
 *
 * @param {String} formula - a `capsuleSeries` formula
 *
 * @returns {Promise} resolves when the formula is loaded and selection is restored
 */
export function loadFormulaAndSetSelection({ formula }: { formula: string }) {
  return Promise.resolve()
    .then(() => flux.dispatch('CAPSULE_GROUP_SET_LOADING'))
    .then(() => requestCapsulesFromFormula(formula))
    .then((c) => _.tap(c, setCapsules))
    .then((capsules) => {
      const conditionSelection = _.filter(capsules, 'id');
      const existingSelection = sqTrendCapsuleStore.selectedCapsules;
      replaceCapsuleSelection(conditionSelection);

      // Give the user the option of adding their selection to the condition instead of having it be lost
      if (_.differenceBy(existingSelection, conditionSelection, 'id').length > 0) {
        const toolName = sqInvestigateStore.activeToolName || 'CAPSULE_GROUP';
        const addPreviousSelection = () => {
          replaceCapsuleSelection(_.unionBy(sqTrendCapsuleStore.selectedCapsules, existingSelection, 'id'));
        };
        infoToast(
          {
            messageKey: 'INVESTIGATE_TOOLS.MANUAL_CONDITION.CAPSULE_GROUP_PICKING.SELECTION_CLEARED',
            buttonAction: addPreviousSelection,
            buttonLabelKey: 'INVESTIGATE_TOOLS.MANUAL_CONDITION.CAPSULE_GROUP_PICKING.SELECTION_CLEARED_ADD_INSTEAD',
            buttonLabelParams: { toolName },
            buttonIcon: 'fa-plus-circle',
          },
          {
            autoClose: 120_000,
          },
        );
      }
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Adds the capsule represented by the selection to the capsule group store and removes the selection.
 */
export function pickSelectedRegion({ formCapsule }: { formCapsule?: CapsuleGroupCapsule }) {
  const capsule = formCapsule || {
    startTime: sqTrendStore.selectedRegion.min,
    endTime: sqTrendStore.selectedRegion.max,
    properties: [],
  };

  flux.dispatch('CAPSULE_GROUP_ADD_CAPSULE', { capsule });
  removeTrendSelectedRegion();
}
